@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



.HeaderTop{
    /* position: fixed; */
    width: 100%;
    /* height: 4rem; */
    display: flex;

    /* background-color: black; */

}


.LogoCont{

    width: 11rem;
    height: 3rem ;

    /* padding: .4rem .4rem; */
    background-color: black;
    /* margin: 1rem; */
    position: absolute;
    top: 1rem;
    left: 1rem;
    border-radius: 0rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}


.Logo{

    /* width: 8rem; */
    font-size: 1.5rem;
    letter-spacing: .4rem;
    /* margin: 1.6rem 1.5rem; */
    /* fill: white; */
    color: white;
    /* fill: rgb(102, 239, 102); */
    /* position: absolute; */
    /* font-family: 'Montserrat '; */
    font-weight: 800;
    text-decoration:none;

}

.Logo:hover {
    /* fill: red;         */

}
