.Space1{
        /* height: 10rem; */
        background-color: rgb(197, 247, 181);
}

.LoginDiv{

    margin-top: 6rem;
    width:50%;
    /* border: 1px solid grey; */
    border-radius: 2rem;
    background-color: white;
    height: 80%;
    display: flex;
    justify-content: center;
    justify-items: center;

}


@media screen and (max-width:720px ){

.LoginDiv{
    margin-top: 6rem;
    width:90%;
    height: auto;
    margin-bottom: 4%;
}


}


.LoginBg{ 
    margin-top:-4rem;
    background-color: rgb(197, 247, 181);
    width: 100%;
    /* height:20rem; */
    height: 100%;
    position: absolute;
    z-index:-1;
    display: flex;
    justify-content: center;
    /* justify-items: center; */
}

.LoginPgHead{
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    text-align: center;
    width: 70%;
    margin: 2rem 0rem ;
    line-height: 1.8rem;
    padding: .5rem 2rem;
    /* position: absolute; */
    /* top:40%; */
    align-self: center;
    /* justify-items: center; */
}

.LoginBt{

    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: center;
    padding: .5rem 1rem;
    border: 1px solid black;
    border-radius: 1rem;
    /* float: right; */
    position: absolute;
    right: 1.2rem;
    /* left: 100px; */
    /* top: 10rem; */
    /* fill: white; */
    margin-top: 1.1rem;
    color:black;
    text-decoration: none;
    
}

.LoginBt:hover {
    background-color: black;
    fill: black;
    color: white;
    transition-duration: .5s;
    /* transition-property: fill; */
    transition-property: color;

}

.LoginPgApplyBt{

    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: center;
    padding: .6rem 1rem;
    border: 1px solid black;
    border-radius: 1rem;
   
    /* position: absolute; */
    /* margin: 0 auto; */
    margin-top: 2.5rem;
    color:black;
    text-decoration: none;

}
.LoginPgApplyBt:hover {
 
    background-color: black;
    fill: black;
    color: white;
    transition-duration: .5s;
    /* transition-property: fill; */
    transition-property: color;

}

.LoginPgApplyHead{

    font-size: .8rem;
    width: 70%;
    line-height: 1.3rem;
    margin: 0 auto;
    text-align: center;
    padding-top: 1rem;
}