@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.GreenbgDiv{

    background-color: rgb(197, 247, 181);
    width: 100%;
    height: 100vh;
    /* position: fixed; */

}

.HomeBg{

    /* background-color: rgb(197, 247, 181); */
    width: 100%;
    height: auto;
    /* height:100%; */

    /* position: absolute; */
    z-index:-1;
    display: flex;
    justify-content: center;
    justify-items: center;
    /* margin-top: -4rem; */
    
}



.HomeHead{

    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    color: black;
    font-weight: 200;
    text-align: center;
    /* margin-top: 25%; */
    
    width: 60%;
    align-self: center;

}


@media screen and (min-width:720px ){

.HomeDispMenu{

    padding-top: 8rem;
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 60%;
    

}

.HomeDispMenuStyle {
    /* padding: 6rem; */
    width: 8rem;
    /* height: 10rem; */
    background-color: white;
    border-radius: 1rem;
    text-align: center;
    padding-top: 1rem;
    margin: .5rem;
    
}

.HomeDispMenu a {

    text-decoration: none;
    /* height: 10rem; */
    

}


.MenuHead{

    font-size: 1rem;
    color: black;
    font-family: 'Montserrat';

    font-weight: 500;
    padding-bottom: 1rem;

}

.HomeIcons{
    width: 3rem;
    padding-top:  2rem;

}


}





@media screen and (max-width:720px ){

.HomeHead{
    font-size: 1.6rem;
    width: 75%;

        
}


.HomeDispMenu{

    padding-top: 5rem;
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    vertical-align: top;
    

}

.HomeDispMenuStyle {
    /* padding: 6rem; */
    width: 6rem;
    /* height: 8rem; */
    background-color: white;
    border-radius: 1rem;
    text-align: center;
    padding-top: 1rem;
    margin: .5rem;
    /* border: 1px solid rgb(240, 240, 240); */
    
}

.HomeDispMenu a {
    
    text-decoration: none;
    margin:0;
    /* height: 4rem; */

}

.HomeDispMenu Link {
    
    /* text-decoration: none; */
    
    /* height: 4rem; */
    /* margin:0; */

}


.MenuHead{

    
    font-size: 0.8rem;
    
    color: black;
    font-family: 'Montserrat';

    font-weight: 500;
    padding-bottom: 1rem;

}

.HomeIcons{
    width: 3rem;
    padding-top:  1rem;

}


    
}




