.Head{

    font-size: 1rem;
}

.CardFoodItems{

    width: 20rem;
    height: 10rem;
    background-color: white;
    border-radius: 1rem;
    text-align: center;
    padding-top: 1rem;
    margin: 1rem;
    border: 1px solid grey;
    
}




.SearchBarDiv{
    margin-top: 2rem;
    /* position:fixed ; */
    /* margin: 0 ; */
    width: 20rem;
    margin-left: 2rem;;


}


.SearchBarFoodsPg{

    font-size: .8rem;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid grey;
    padding: .5rem .5rem;
    border-radius: .4rem; 
    margin: 1rem;
    /* position: relative; */

}

.AllFoodsDiv{

    display: flex;
    flex-wrap: wrap; 
    /* justify-content: flex-start; */
    padding-top: 0rem;
    justify-content: center;
    /* position: relative; */
}
